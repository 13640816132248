import { v4 as uuidv4 } from 'uuid'
import { localDeviceUuid } from '@libs/utils/utilLocalStorage'

export const createDeviceUuid = (): string => {
  const localUuid = localDeviceUuid.load()
  if (localUuid) {
    return localUuid
  }

  const _uuid = uuidv4()
  localDeviceUuid.save(_uuid)

  return _uuid
}
