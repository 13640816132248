import { ModelInitData } from 'containers/models'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { message as antdMessage } from 'antd'
import Router, { SingletonRouter, withRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import NProgress from 'nprogress'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache, ApolloClient, ApolloLink, ApolloProvider, useLazyQuery } from '@apollo/client'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils/throwServerError'

// global css
import 'antd/dist/antd.css'
import 'react-image-lightbox/style.css'
import 'react-quill/dist/quill.snow.css'
import '../../styles/global.css'

import { setContext } from '@apollo/client/link/context'
import { wrapperStore } from '@libs/redux/store'
import * as utilCommon from '@libs/utils/utilCommon'
import * as utilLocalStorage from '@libs/utils/utilLocalStorage'
import * as utilCrypto from '@libs/utils/utilCrypto'
import { doSetInitData } from '@libs/redux/reduxData'
import { themes, ThemeProvider } from '@constants'
import ROUTES from '@constants/constRoutes'
import useUserInfo from 'containers/hooks/useUserInfo'
import { LayoutPage } from 'domains/common/layouts'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import useMMutation, { onErrorType } from 'containers/hooks/useMMutation'
import { IModelUser } from 'containers/models/modelUser'
import { CLIENT_TYPE, ERROR_CODE } from '@constants/constData'
import COMMON_QUERY_GQLS from 'containers/gqls/common/queries'
import Head from 'next/head'

// typescript overall
// https://heropy.blog/2020/01/27/typescript/

// typescript react usage
// https://velog.io/@velopert/create-typescript-react-component
// https://hyunseob.github.io/2018/07/15/component-typing-in-react/ (조금 옛날 글, React Type)

Router.events.on('routeChangeStart', () => {
  // console.log(`Loading: ${url}`)
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// https://stackoverflow.com/questions/59465864/handling-errors-with-react-apollo-usemutation-hook
// https://yongkshire.tistory.com/2
// TODO yoon: next.config에서 설정된 값을 가져오고 싶으나, "import getConfig from 'next/config'"가 제대로 동작하지 않음
const uploadLink = createUploadLink({ uri: process.env.API_URL })

const authLink = setContext((_, { headers }) => {
  const userToken = utilLocalStorage.localUserToken.load()
  const userRole = useUserInfo.loadUserRole()
  // console.log(userToken)
  return {
    // fetchOptions: { method: 'POST' },
    headers: {
      ...headers,
      Authorization: userToken !== undefined || userToken ? `Bearer ${userToken}` : null,
      ...(userRole &&
        userRole?.currentRole?.type &&
        userRole?.currentRole?.key && {
          'user-mode': userRole.currentRole.type,
          'user-key': userRole.currentRole.key,
        }),
      // 'Content-Type': 'application/json',
    },
  }
})

const errorLink = onError((errorResponse: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = errorResponse

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // need validate code
      let error
      try {
        error = JSON.parse(message)
      } catch (e) {
        error = message
      }
      if (typeof error === 'string') {
        if (process.browser && document) {
          antdMessage.error(error)
        }
      } else {
        const { code, message: errorMessage } = error
        if (![ERROR_CODE.ERROR_USER_INFO_DOES_NOT_EXISTS, ERROR_CODE.WRONG_CERTIFICATION_CODE].includes(code)) {
          if (errorMessage) {
            antdMessage.warn(errorMessage)
          }
        }

        if (code === ERROR_CODE.VALIDATION_ERROR_AUTHENTICATION_EXPIRED) {
          Router.push(ROUTES.LOGIN)
        }
      }
      // console.log(`errorCode=[${code}]\nerrorMessage=[${errorMessage}]\nPath: [${path}]`)
    })
  }

  if (networkError) {
    const whoamiData = useUserInfo.loadWhoamiData()
    const userData = whoamiData?.user?.data as IModelUser
    const { operationName, variables } = operation

    Sentry.captureException(networkError, {
      ...(userData && {
        user: {
          id: userData._id,
          username: userData.username,
          email: userData.email,
          name: userData.name,
        },
      }),
      // level: Severity
      extra: {
        operationName,
        variables: JSON.stringify(variables),
        graphQLErrors,
      },
      // contexts: {
      //   state: {
      //     state: {
      //       type: 'redux',
      //       value: JSON.stringify(store.getState()),
      //     },
      //   },
      // },
      // tags: {
      // [key: string]: Primitive
      // }
      // fingerprint: string[]
      // requestSession: RequestSession
    })

    const {
      statusCode,
      // result
    } = networkError as ServerError
    // const errorMessage = result?.errors?.[0]?.message
    // antdMessage.error(`API 호출에 실패 하였습니다. StatusCode=[${statusCode}], Message=[${errorMessage}]`)
    if (statusCode === 401) utilCommon.cleanAndGoToLogin(Router)
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  // @ts-ignore
  link: ApolloLink.from([errorLink, authLink.concat(uploadLink)]),
  defaultOptions: {
    watchQuery: {},
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

const isRequiredLoginMenu = (pathname: string) => {
  const notRequiredLoginMenu = [
    ROUTES.SIGNUP,
    ROUTES.LOGIN,
    ROUTES.FIND_ID,
    ROUTES.FIND_PASSWORD,
    ROUTES.POLICIES.TERMS,
    ROUTES.POLICIES.PRIVACY,
    ROUTES.REGISTER_BRAND_MANAGER,
    ROUTES.NOTIFY_SUSPEND_ACCOUNT,
  ]
  for (let idx = 0; idx < notRequiredLoginMenu.length; idx += 1) {
    if (pathname.startsWith(notRequiredLoginMenu[idx])) {
      return false
    }
  }
  return true
}

const InitSetting = ({ router }: { router: SingletonRouter }) => {
  const userToken = utilLocalStorage.localUserToken.load()
  const ableToSetErrorMessageRef = useRef<boolean>(false)
  const { saveTokenNWhoami } = useUserInfo.useSaveUserInfo()
  const { cleanUserInfo } = useUserInfo
  const dispatch = useDispatch()

  const onTokenSigninAPISuccess = (data: any) => {
    if (!data) {
      // Token exist, but server return nothing -> invalid
      utilCommon.cleanAndGoToLogin(router)
    } else {
      const {
        user: { data: user },
        device: {
          data: { token },
        },
      } = data
      // save token and whoami data on redux
      saveTokenNWhoami(token, data)

      if (!(user.isStaff || user.isSeller)) {
        // 슈퍼 유저, 브랜드 매니저 또는 셀러가 아니면 브랜드 셀러 등록 안내 화면으로 이동
        cleanUserInfo()
        router.replace(
          useUserInfo.isSuspendSeller(user) ? ROUTES.NOTIFY_SUSPEND_ACCOUNT : ROUTES.REGISTER_BRAND_MANAGER
        )
      } else if (!isRequiredLoginMenu(router.pathname)) {
        router.replace(ROUTES.DASHBOARD)
      }
    }
  }

  const onErrorToken: onErrorType = (error) => {
    if (ableToSetErrorMessageRef.current) {
      ableToSetErrorMessageRef.current = false
      console.log('error : ', error)
      // antdMessage.warn('로그인이 필요합니다!')
    }
    router.replace(ROUTES.LOGIN)
  }

  const [tokenSigninMutation] = useMMutation('tokenSignin', onTokenSigninAPISuccess, onErrorToken)
  // const { data } = useMQuery('initData', undefined)
  // if (data && data.initData) {
  //   dispatch(doSetInitData({ ...data.initData.data }))
  // }
  const [initDataQuery] = useLazyQuery(COMMON_QUERY_GQLS.INIT_DATA, {
    onCompleted: (data) => {
      if (data && data.initData) {
        const initData = new ModelInitData(data.initData)
        dispatch(doSetInitData({ ...initData }))
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const tokenSigninVariables = MUTATION_VARIABLES.TOKEN_SIGNIN({
      device: {
        token: userToken,
        clientType: CLIENT_TYPE.WEB,
        uuid: utilCrypto.createDeviceUuid(),
      },
    })
    const mutate = async () => {
      // @ts-ignore
      const res = await tokenSigninMutation({ variables: tokenSigninVariables })
      if (res.errors?.length) {
        if (isRequiredLoginMenu(router.pathname)) {
          utilCommon.cleanAndGoToLogin(router)
        } else {
          utilCommon.cleanLocalStorage()
        }
      }
    }
    if (userToken) {
      ableToSetErrorMessageRef.current = true
      mutate()
    } else if (isRequiredLoginMenu(router.pathname)) {
      utilCommon.cleanAndGoToLogin(router)
    } else {
      utilCommon.cleanLocalStorage()
    }
    initDataQuery()
  }, [])
  return null
}

function WrappedApp(props: any) {
  const { Component, pageProps, router } = props
  return (
    <ApolloProvider client={client}>
      <InitSetting router={router} />
      <ThemeProvider theme={themes}>
        <Head>
          <title>핏펫몰 관리자</title>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="google-site-verification" content="bqPhvTRXuO7lRj_8sZCgZ91fhqviHYgakxVpHkp5JxA" />
        </Head>
        {isRequiredLoginMenu(router.pathname) && (
          <LayoutPage>
            <Component {...pageProps} />
          </LayoutPage>
        )}
        {!isRequiredLoginMenu(router.pathname) && <Component {...pageProps} />}
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default wrapperStore.withRedux(hot(withRouter(WrappedApp)))
